<template>
  <div class="page">
    <div class="header-container">
      <div class="back-item">
        <router-link class="content link" to="/login">← {{ $t('General.Back') }}</router-link>
      </div>
    </div>
    <div class="header-space"></div>
    <div>
      <label class="title">{{ $t('Login.Forgot') }}</label>
    </div>
    <div class="header-space"></div>
    <div class="login-form">
      <div>
        <TextBox :placeholder="$t('Login.ResetMail')" :type="'email'" :value="email" @input="email = $event"></TextBox>
      </div>
      <div @click="requestReset">
        <Button :text="$t('Login.Reset')" :disabled="!valid"></Button>
      </div>
    </div>
  </div>
</template>

<script>
import TextBox from '../components/TextBox.vue'
import Button from '../components/Button.vue'

export default {
  name: "Start",
  components: {
    TextBox, Button
  },
  data() {
    return {
      email: "",
    };
  },
  methods: {
    async requestReset() {
      if (!this.valid) {
        this.toast("Fehler", "Ungültige Eingabe", "danger");
        return;
      }
      // authotizes user from the backend
      this.axios
        .post("auth/requestReset", {
          email: this.email,
        })
        .then((res) => {
          if (res.status !== 200)
            return this.toast(
              "Es ist ein Fehler aufgetreten",
              "Ungültige Login-Daten",
              "danger"
            );
          this.$router.push("/login");
        })
        .catch((/* err */) => {
          this.toast(
            "Account vergeben",
            "Bitte verwende eine andere E-Mail-Adresse",
            "danger"
          );
        });
    },
  },
  computed: {
    mailValid() {
      const regex = RegExp(/^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,8}$/);
      return regex.test(this.email);
    },
    valid() {
      return this.mailValid;
    },
  },
};
</script>

<style lang="scss" scoped>
.login-form {
  display: flex;
  flex-direction: column;
  gap: 1em;
}
</style>
